// import React, { useState, useEffect } from 'react';

// function TypingEffect({ text, speed, onComplete }) {
//   const [displayText, setDisplayText] = useState('');
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     let interval;
//     if (currentIndex < text?.length) {
//       interval = setInterval(() => {
//         setDisplayText((prevText) => prevText + text[currentIndex]);
//         setCurrentIndex((prevIndex) => prevIndex + 1);
//       }, speed);
//     } else {
//       onComplete();
//     }

//     return () => clearInterval(interval);
//   }, [text, speed, currentIndex, onComplete]);

//   const formatTextWithBoldNumbers = (text) => {
//     return text.split("\n").map((line, index) => {
//       const parts = line.split(/\b(\d+)\b/); 
//       return (
//         <React.Fragment key={index}>
//           {parts.map((part, partIndex) => {
           
//             const isNumber = /^\d+$/.test(part.trim());
//             return isNumber ? <strong key={partIndex}>{part}</strong> : part; 
//           })}
//           <br />
//         </React.Fragment>
//       );
//     });
//   };

//   return (
//     <>
//       {formatTextWithBoldNumbers(displayText)}
//     </>
//   );
// }

// export default TypingEffect;
import React, { useState, useEffect } from 'react';

function TypingEffect({ text, speed, onComplete }) {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let interval;
    if (currentIndex < text?.length) {
      interval = setInterval(() => {
        setDisplayText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, speed);
    } else {
      onComplete();
    }

    return () => clearInterval(interval);
  }, [text, speed, currentIndex, onComplete]);

  const formatTextWithBoldNumbersAndLinks = (text) => {
    return text.split("\n").map((line, index) => {
      const parts = line.split(/\b(\d+|\bhttps?:\/\/[^\s]+)\b/);
      return (
        <React.Fragment key={index}>
          {parts.map((part, partIndex) => {
            const isNumber = /^\d+$/.test(part.trim());
            const isLink = /^https?:\/\/[^\s]+$/.test(part.trim());

            if (isNumber) {
              return <strong key={partIndex}>{part}</strong>;
            } else if (isLink) {
              // const fileName = part.split('/').pop(); // Extract the filename from the link
              return (
                <a
                  key={partIndex}
                  href={part}
                  className="anchor-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {part}
                </a>
              );
            } else {
              return part;
            }
          })}
          <br />
        </React.Fragment>
      );
    });
  };

  return (
    <>
      {formatTextWithBoldNumbersAndLinks(displayText)}
    </>
  );
}

export default TypingEffect;
